import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { IKeyValueType } from 'src/app/enums/Enums';
import { AccountService } from 'src/app/services/account.service';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'variaves-regulamento',
  standalone: true,
  imports: [CommonModule, OverlayPanelModule],
  templateUrl: './variaves-regulamento.component.html',
  styleUrl: './variaves-regulamento.component.css',
})
export class VariavesRegulamentoComponent implements OnInit {
  @Input() areaCliente = false;

  variaveisDisponiveis?: Map<string, IKeyValueType>;
  showListaVariaveis = false;
  varCopiada?: string = '';

  constructor(
    private accountService: AccountService,
    private utility: UtilitiesService
  ) {}

  ngOnInit(): void {
    // this.carregaVariaveis();
  }

  carregaVariaveis() {
    this.variaveisDisponiveis = undefined;
    const empresa = this.accountService.currentEmpresaLogada;

    if (empresa) {
      this.variaveisDisponiveis = this.utility.getVariaveisComValor(empresa, this.areaCliente);
    }
  }

  copyVariavel(varName: string) {
    if (varName) {
      navigator.clipboard.writeText(`[${varName}]`).then(
        () => {
          this.varCopiada = varName; // Mostrar mensagem de sucesso
        },
        (err) => {
          console.error('Erro ao tentar copiar o texto: ', err);
        }
      );
    }
  }
}
