<div
class="w-fit font-semibold px-2 py-0.5 border rounded-xl cursor-pointer text-sm bg-topvix-button text-topvix-primary"
(mouseenter)="carregaVariaveis(); opVariaveisRegulamento.toggle($event)"
>
Variáveis disponíveis
</div>

<p-overlayPanel #opVariaveisRegulamento>
    <div class="w-fit flex flex-col gap-3 px-2 py-1">
      <div class="flex justify-start items-center gap-2 border-b">
        <span class="font-semibold text-black text-sm"
          >Variáveis disponívels e o valor atual</span
        >
      </div>
      <div>
        <ul class="list-none p-0 m-0 flex flex-col gap-1 max-h-[10rem] overflow-auto">
          <li
            *ngFor="let variavel of variaveisDisponiveis | keyvalue"
            class="px-2 flex justify-start items-center gap-2 bg-gray-100 hover:bg-gray-200 hover:transition-all hover:delay-150 hover:duration-200 duration-100 delay-75 transition-all hover:rounded-e-md hover:rounded-s-md hover:shadow cursor-pointer group"
          >
            <div class="flex justify-between items-center gap-2">
              <div
                class="h-fit flex flex-col justify-start items-center gap-1 font-semibold text-xs px-1 border rounded-md bg-gray-200"
              >
                <div class="w-full flex items-center gap-2">
                  <span class="font-semibold text-sm">[{{ variavel.key }}]</span>
                  <span class="font-semibold text-lg">=</span>
                  <span class="font-normal text-xs">{{
                    variavel.value.varValue
                  }}</span>
                </div>
                <span class="w-full text-xs font-light">{{ variavel.value.varDescription }}</span>
              </div>
            </div>
            <div
              (click)="copyVariavel(variavel.key)"
              class="flex justify-center items-center gap-1 ml-auto text-xs font-normal px-2 border rounded-lg shadow hover:font-bold group-hover:scale-110 group-hover:cursor-pointer bg-topvix-button text-topvix-primary"
            >
              <span
                *ngIf="variavel.key === varCopiada"
                class="icon-[mdi--check-circle-outline] w-5 h-5 text-green-500"
              ></span>
              <span *ngIf="variavel.key === varCopiada" class="text-green-200"
                >Copiada</span
              >
              <span *ngIf="variavel.key !== varCopiada" class=""
                >Copiar variável</span
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </p-overlayPanel>
